import {config,publicPara,profile,dataSource,isSandBox} from "./config";
import {getId} from "./anonymous";
import  {track} from './api'
import  {setProfileCookie,getProfileCookie} from './user'
import {removeHtmlTagsAndNewLines} from  './util'
let setPara = function (para) {
  Object.assign(config,para);
  console.log(config);
}

let registerPublicProperties = function (properties) {
  Object.assign(publicPara,properties);
  console.log(publicPara);
}

let setSource = function (source) {
  dataSource = source;
}

let setSandBox = function (sandBox) {
  isSandBox = sandBox == true?1:0;
}

let init = function () {

  let pf = getProfileCookie();
  if (pf) {
    profile = pf;
    if (!profile.cookieId) {
      profile.cookieId = getId();
      setProfileCookie();
    }
  } else {
    profile.cookieId = getId();
    setProfileCookie();
  }

  if (config.auto_track) {
    if (typeof config.auto_track == 'object') {
      track('view_page', config.auto_track);
    } else {
      track('view_page', {});
    }

  }
  if (config.heatmap.clickmap == true) {
    setTimeout(function () {
      let nodeList=[],attrList=[],tagList=[];
      if(config.heatmap.track_attr){
        attrList = document.querySelectorAll('[' + config.heatmap.track_attr + ']');
      }
      if(config.heatmap.collect_tags){
        let tags = [];
        for(let key in config.heatmap.collect_tags) {
          if(key && config.heatmap.collect_tags[key])
          tags.push(key);
        }
        tagList = document.querySelectorAll(tags.join(','));
      }
      nodeList = [...attrList,...tagList];
      nodeList.forEach(function (el) {
        el.addEventListener('click', function (e) {
          let param = {
            element_content: removeHtmlTagsAndNewLines(e.currentTarget.innerText),
            pageX: e.pageX,
            pageY: e.pageY,
            element_target_url: ''
          }
          if (e.target.nodeName == 'IMG') {
            param.element_content = e.target.alt;
          }
          if (e.target.nodeName == 'A') {
            param.element_target_url = e.target.href;
          }
          track('click_page', param);
        });
      });
    }, 0)
  }
}

export {setSource,setPara,registerPublicProperties,init,setSandBox};